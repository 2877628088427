<template>
  <div>
    <router-link class="come-back" to="/admin/members/">
      <div></div>
      Вернуться назад
    </router-link>
    <h2 class="title_categories">{{ $route.query.type === 'edit' ? 'Редактирование Члена ОПРФ' : 'Создание члена ОПРФ' }}</h2>
    <tricolor-line class="card_containet__tricolor"/>
    <form @submit.prevent="creteMember" ref="createMember">
      <div>
        <div class="content-sb">
          <p>Имя<span>*</span></p>
          <input required placeholder="Введите имя" v-model="editData.firstName" type="text" name="firstName" class="input blue">
        </div>
        <div class="content-sb">
          <p>Фамилия<span>*</span></p>
          <input required placeholder="Введите фамилию" type="text" v-model="editData.lastName" name="lastName" class="input blue">
        </div>
        <div class="content-sb">
          <p>Отчество</p>
          <input placeholder="Введите отчество" type="text" v-model="editData.patronymic" class="input blue">
        </div>
        <div class="content-sb">
          <p>Имя (Англ)</p>
          <input placeholder="Введите имя (Англ)" v-model="editData.firstNameEng" type="text" name="firstNameEng" class="input blue">
        </div>
        <div class="content-sb">
          <p>Фамилия (Англ)</p>
          <input placeholder="Введите фамилию (Англ)" type="text" v-model="editData.lastNameEng" name="lastNameEng" class="input blue">
        </div>
        <div class="content-sb">
          <p>Отчество (Англ)</p>
          <input placeholder="Введите отчество (Англ)" type="text" v-model="editData.patronymicEng" class="input blue">
        </div>
        <div class="content-sb">
          <p>Руководитель ?</p>
            <div class="leader-check">
              <input
                :checked="$route.query.type === 'edit' && editData.systemTagList.length !== 0 ? true : false"
                ref="checkLeader"
                class="input blue"
                type="checkbox"
              >
            </div>
        </div>
        <div class="content-sb">
          <p>Эксперт ?</p>
            <div class="leader-check">
              <input
                class="input blue"
                type="checkbox"
                v-model="expert"
              >
            </div>
        </div>

        <div class="content-sb">
          <p>Кем утвержден</p>
          <select  @change="changeInclusionGround" class="input blue"  name="inclusionGround">
            <option v-if="this.$route.query.type === 'create'" disabled selected>Выберите кем утвержден</option>
            <option v-for="item in $store.getters.getInclusionGround"  :selected="editData.inclusionGround === item.title" :key="`getSelectYear${item.id}`" :value="item.code">{{ item.title }}</option>
          </select>
        </div>
        <div class="content-sb">
          <p>Место работы</p>
          <tags @dataTags="dataTags" :startValue="$route.query.type === 'edit' ? editData.outsideJob : []" placeholder="Введите места работы через запятую"/>
        </div>
        <div class="content-sb">
          <p>Место работы (Англ)</p>
          <tags @dataTags="dataTagsEng" :startValue="$route.query.type === 'edit' && editData.outsideJobEng !== null ? JSON.parse(editData.outsideJobEng) : []" placeholder="Введите места работы через запятую"/>
        </div>
        <div v-if="$route.query.type === 'edit'" class="content-sb">
          <p>Старая фотография</p>
          <img class="old-photo" :src="`${$store.getters.getUrlApi}${editData.photo}`" alt="">
        </div>
        <div class="content-sb">
          <p>Фотография</p>
          <input
            class="input blue"
            id="photo"
            type="file"
            name="photo"
          >
        </div>
        <div class="content-sb">
          <p>Биография </p>
          <ckeditor :config="$editorConfig" :editor-url="$root.editorUrl" v-model="editData.biography"></ckeditor>
        </div>
        <div class="content-sb">
          <p>Биография (Англ)</p>
          <ckeditor :config="$editorConfigEng" :editor-url="$root.editorUrl" v-model="editData.biographyEng"></ckeditor>
        </div>
        <div class="content-sb">
          <multiple-choices @multi="multi" :startValue="$route.query.type === 'edit' ? editData.convocations : []"/>
        </div>
      </div>
      <footer class="content-sb btns-create-form">
        <router-link to="/admin/members" class="btn-white">Отмена</router-link>
        <input type="submit" class="btn-blue" :value="$route.query.type === 'edit' ? 'Изменить' : 'Сохранить'"/>
      </footer>
    </form>
  </div>
</template>

<script>
import TricolorLine from '@/components/TricolorLine'
import Tags from '@/components/Admin/Tags'
import MultipleChoices from '@/components/Admin/MultipleChoices'

export default {
  name: 'createUser',

  data () {
    return {
      editData: this.$route.query.type === 'edit' ? this.$store.getters.getDetailMember : {
        firstName: '',
        tags: [],
        tagsEng: [],
        biography: '',
        convocations: '',
        patronymic: null,
        firstNameEng: '',
        lastNameEng: '',
        patronymicEng: '',
        biographyEng: ''
      },
      systemTagList: ['APPARATUS_CEO'],
      expert: this.$route.query.type === 'edit' ? this.$store.getters.getDetailMember.expert : false
    }
  },

  mounted () {
    if (this.$route.query.type === 'edit' && this.$store.getters.getDetailMember === null) {
      this.$router.push('/admin/members/')
    }
  },

  methods: {
    changeRole (e) {
      this.form.role = e.target.value
    },
    creteMember () {
      const formData = new FormData(this.$refs.createMember)

      formData.append('expert', this.expert)
      formData.append('biography', this.editData.biography)
      formData.append('biographyEng', this.editData.biographyEng)
      formData.append('outsideJob', JSON.stringify(this.editData.tags))
      formData.append('outsideJobEng', JSON.stringify(this.editData.tagsEng))

      if (this.editData.convocations) {
        formData.append('convocations', this.formatMulty(this.editData.convocations))
      }
      if (this.editData.patronymic) formData.append('patronymic', this.editData.patronymic)

      if (this.editData.patronymicEng) formData.append('patronymicEng', this.editData.patronymicEng)

      if (this.$route.query.type === 'edit') {
        if (this.$refs.checkLeader.checked) formData.append('systemTagList', JSON.stringify(this.systemTagList))
        this.$store.dispatch('editMember', { data: formData, id: this.$store.getters.getDetailMember.id })
      } else {
        this.$store.dispatch('createMember', formData)
      }
    },
    changeInclusionGround (e) {
      this.editData.inclusionGround = e.target.value
    },
    dataTags (value) {
      this.editData.tags = value
    },
    dataTagsEng (value) {
      this.editData.tagsEng = value
    },
    multi (value) {
      this.editData.convocations = value
    },
    formatMulty (convocations) {
      return JSON.stringify(convocations.map((convocation) => {
        return {
          convocationId: convocation.id,
          units: convocation.units.map((unit) => {
            return {
              unitId: unit.id,
              position: unit.code
            }
          })
        }
      }))
    }
  },

  computed: {
    getDetailMember () {
      return this.$store.getters.getDetailMember
    },
    getRole () {
      return this.$store.getters.getRole
    },
    yearUnitsList () {
      return this.$store.getters.getSelectYear[0].map(unit => unit.name)
    }
  },

  components: {
    TricolorLine,
    Tags,
    MultipleChoices
  }
}
</script>

<style lang="scss">
.btns-create-form {
  border-top: 1px solid #D5D6D9;
  padding-top: 2.5rem;
  margin-top: 2.5rem;
}

.content-sb {
  margin-bottom: 1.25rem;

  span {
    color: red;
  }
  .leader-check {
    width: 33.75rem;
    display: flex;
    justify-content: flex-start;
    input {
      width: auto;
    }
  }
}

.select, .input {
  width: 33.75rem;
}

.old-photo{
  width: 10rem;
}
</style>
