<template>
  <div class="wrapper-multy">
    <div class="multy-item" v-for="(item, i) in convocations" :key="`convocations${item.id}`">
      <div class="content-sb">
        <p>Состав<span>*</span></p>
        <select required @change="selectYear($event, i)" ref="selectYear" class="input blue" name="convocationId">
          <option disabled selected>Выберите состав</option>
          <option v-for="convocation in $store.getters.getConvocation"
                  :selected="convocation.id === item.id"
                  :key="`convocationOption${convocation.id}`"
                  :value="convocation.id">
            {{ convocation.name }}
          </option>
        </select>
      </div>

      <div style="width: 100%" >
        <div v-for="(obj, j) in item.units" :key="`item.units${j}`" style="width: 100%">
          <div class="content-sb">
            <p>Должность<span>*</span></p>
            <select required class="input blue" ref="positions" @change="changePosition($event.target.value, i, j)" >
              <option disabled selected>Выберите должность</option>
              <option v-for="position in getPositions"
                      :selected="position.code === obj.code"
                      :key="`positionOption${position.id}-${j}`"
                      :value="position.code">
                {{ position.title }}
              </option>
            </select>
          </div>

          <div class="content-sb">
            <p>Название отдела<span>*</span></p>
            <select required class="input blue" ref="unitId" @change="changeUnit($event.target.value, i, j)">
              <option disabled selected>Выберите название</option>
              <option v-for="unit in getSelectYear[i]"
                      :selected="unit.id === obj.id"
                      :key="`unitOption-id-${unit.id}`"
                      :value="unit.id">
                {{ unit.name }}
              </option>
            </select>
          </div>
          <div>
            <button class="btn-red" @click.prevent="delDopSection({ i, j })">Удалить данную доп. должность</button>
          </div>
        </div>
        <button v-if="$store.getters.getSelectYear[i]" class="btn-blue btn-add"  @click.prevent="addDopSection(i)">Добавить данную доп. должность</button>
        <button v-if="statusMenu && $store.getters.getConvocation.length >= 2"  class="btn-red" @click.prevent="delSection(i)">удалить данный раздел</button>
      </div>
    </div>
    <button v-if="statusMenu && $store.getters.getConvocation.length >= 2" class="btn-blue btn-add" @click.prevent="addSection">Добавить поле "Год созыва"</button>
  </div>
</template>

<script>
export default {
  name: 'MultipleChoices',

  props: {
    startValue: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      convocations: [
        {
          id: null,
          units: []
        }
      ],
      statusMenu: false
    }
  },

  mounted () {
    if (this.$route.query.type === 'edit') {
      this.statusMenu = true
      this.startValue.forEach((item, i) => {
        this.$store.dispatch('setSelectYear', {
          value: item.id,
          index: i
        })
      })
      this.convocations = JSON.parse(JSON.stringify(this.startValue))
      this.emit()
    }
  },

  methods: {
    addSection () {
      this.convocations.push({
        id: null,
        units: [
          {
            id: null,
            code: null
          }
        ]
      })
    },

    addDopSection (i) {
      let noUnit = this.$store.getters.getSelectYear[i]
      if (noUnit) {
        noUnit = noUnit.filter(item => item.name === 'Без отдела')
      }
      this.convocations[i].units.push({
        id: null,
        code: null
      })
    },

    delSection (i) {
      this.convocations.splice(i, 1)
      // this.$store.commit('delSelectYear', i)
      this.emit()
    },

    delDopSection (obj) {
      this.convocations[obj.i].units.splice(obj.j, 1)
      this.emit()
    },

    selectYear (e, index) {
      this.convocations[index].id = +e.target.value
      this.$store.dispatch('setSelectYear', {
        value: e.target.value,
        index
      })
      this.statusMenu = true
      this.emit()
    },

    changePosition (positionCode, convocationIndex, unitIndex) {
      this.convocations[convocationIndex].units[unitIndex].code = positionCode
      this.emit()
    },

    changeUnit (positionCode, convocationIndex, unitIndex) {
      this.convocations[convocationIndex].units[unitIndex].id = +positionCode
      this.emit()
    },

    emit () {
      this.$emit('multi', this.convocations)
    }
  },

  computed: {
    // должности
    getPositions () {
      return this.$store.getters.getPositions
    },
    // отделы
    getSelectYear () {
      return this.$store.getters.getSelectYear
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper-multy {
  width: 100%;
}

.multy-item {
  margin-top: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-bottom: 2rem;
}

.content-sb {
  width: 100% !important;
}

.btn-add {
  margin-top: 2rem;
  color: #fff;
}
</style>
